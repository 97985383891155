import React from "react"
import "../../styles/inner-hero.css"
import VideoContent from "../modules/video-content"
import tw from "twin.macro"

const InnerHero = ({ videoContent, requireH1 }) => (
  <section className="ih" css={!videoContent.vcContent && tw`py-16`}>
    {videoContent.vcContent && (
      <VideoContent
        data-id="hash"
        video={videoContent.vcVideo}
        button={videoContent.vcButton}
        showVideo={videoContent.vcShow}
        title={videoContent.vcTitle}
        content={videoContent.vcContent}
      />
    )}
  </section>
)

export default InnerHero
