import React from "react"
import tw, { styled, css } from "twin.macro"
import LandscapeReview from "./landscape-review"
import Img from "gatsby-image"

const Wrapper = styled.div(() => [
  tw`bg-black text-white py-32 px-16`,
  css`
    @media (max-width: 1440px) {
      ${tw`pt-64`}
    }

    @media (max-width: 640px) {
      ${tw`px-4`}
    }
  `,
])

const FeaturedTestimonial = props => {
  const { featuredReviewTexture } = props
  return (
    <Wrapper>
      <Img
        className="background"
        fluid={featuredReviewTexture?.localFile.childImageSharp.fluid}
        alt="Texture"
      />
      <LandscapeReview {...props} />
    </Wrapper>
  )
}

export default FeaturedTestimonial
