import React from "react"
import tw, { styled, css } from "twin.macro"
import Img from "gatsby-image"
import UnderlinedLink from "./underlined-link"

const Title = styled.p(() => [
  tw`absolute text-xl mb-0`,
  css`
    left: 0rem;
    top: -4rem;

    @media (max-width: 1024px) {
      ${tw`relative w-full text-center`}
    }
  `,
])

const Wrapper = styled.div(() => [
  tw`flex items-center justify-center w-full font-mont font-bold relative`,
  css`
    & > .underlined-link {
      ${tw`absolute after:bg-white hover:after:bg-transparent`}
      bottom: -4rem;
      right: 0;
    }

    @media (max-width: 1024px) {
      ${tw`flex-col`}

      & > .underlined-link {
        right: inherit;
      }
    }
  `,
])

const Stars = tw.div`text-marrone-primary text-4xl font-normal mb-8 leading-none`

const Author = tw.p`text-marrone-primary text-sm uppercase`

const Body = styled.p(() => [
  tw`text-2xl leading-normal my-4`,
  css`
    max-width: 425px;
    @media (max-width: 640px) {
      ${tw`text-xl`}
    }
  `,
])

const Left = styled.div(() => [
  tw`flex relative items-center justify-center w-1/2`,
  css`
    max-width: 450px;
    .gatsby-image-wrapper {
      ${tw`w-full`}
    }

    @media (max-width: 1024px) {
      ${tw`w-full`}
    }
  `,
])

const Right = styled.div(() => [
  tw`flex flex-col items-start justify-center pl-48`,
  css`
    .underlined-link {
      ${tw`text-marrone-primary after:bg-marrone-primary hover:text-white`}
    }

    @media (max-width: 1024px) {
      ${tw`pl-0 items-center text-center`}
    }
  `,
])

const LandscapeReview = ({
  title,
  author,
  body,
  videoEmbed,
  videoButton,
  viewAllButton,
  fluid,
  altText,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Left>
      <Img fluid={fluid} alt={altText} />
    </Left>
    <Right>
      <Stars>
        {Array(5)
          .fill()
          .map((_, index) => (
            <span key={index}>&#9733;</span>
          ))}
      </Stars>
      {author && <Author>- {author}</Author>}
      <Body>“{body}”</Body>
      {videoEmbed && videoButton && (
        <UnderlinedLink embed={videoEmbed}>{videoButton}</UnderlinedLink>
      )}
    </Right>
    <UnderlinedLink link={viewAllButton.url}>
      {viewAllButton.title}
    </UnderlinedLink>
  </Wrapper>
)

export default LandscapeReview
