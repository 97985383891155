import React from "react"
import tw, { css, styled } from "twin.macro"

const Reviews = tw.ul`-my-6 font-bold flex flex-col leading-loose items-center justify-center`
const Excerpt = styled.p(() => [
  tw`mt-4 text-base text-marrone-bright`,
  css`
    @media (max-width: 640px) {
      ${tw`text-sm`}
    }
  `,
])
const Author = tw.p`text-marrone-bright text-xs mt-4`
const Stars = tw.div`flex text-marrone-bright text-4xl`

const Review = styled.li(() => [
  tw`flex flex-col items-center justify-center my-4 text-base text-center`,
  css`
    max-width: 600px;

    p {
      @media (max-width: 640px) {
        ${tw`text-sm`}
      }
    }
  `,
])

const List = ({ reviews }) => {
  const createStars = Array(5)
    .fill()
    .map((_, index) => <span key={index}>&#9733;</span>)
  const listReviews = reviews.map(({ body, excerpt, author }, index) => {
    return (
      <Review key={index}>
        <Stars>{createStars}</Stars>
        <p>
          “{body}
          {!excerpt && `”`}
        </p>
        {excerpt && <Excerpt>{excerpt}”</Excerpt>}
        {author && <Author>- {author}</Author>}
      </Review>
    )
  })
  return <Reviews className="reviews-list">{listReviews}</Reviews>
}

export default List
