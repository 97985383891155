import React from "react"
import tw, { styled, css } from "twin.macro"
import { PopupButton } from "../modules/popup-form"
import Img from "gatsby-image"
import Heading from "./heading"
import Content from "./content"
import { graphql, StaticQuery } from "gatsby"
import ModalContext from "./modal-context"

const rightWidth = 750

const Wrapper = styled.div(() => [
  tw`relative flex items-center justify-center w-full`,
  css`
    @media (max-width: 1280px) {
      ${tw`flex-col`};
    }
    .heading {
      line-height: 1;
    }
  `,
])

const Right = styled.div(({ showVideo, attorneyPage }) => [
  tw`relative flex flex-col items-center justify-start w-full h-full -mb-4`,
  css`
    .button {
      ${tw`mt-4`}
    }
  `,
  !showVideo &&
    css`
      ${tw`text-center`}
      .content {
        ${tw`text-center`}
        max-width: 1200px !important;
      }
    `,
  showVideo && tw`items-start -mt-12`,
  showVideo &&
    css`
      width: ${rightWidth}px;
      @media (max-width: 1440px) {
        ${tw`w-1/2`}
      }

      .content {
        max-width: 600px;
      }

      @media (max-width: 1280px) {
        ${tw`items-center w-full mt-12 text-center`}

        .content {
          ${tw`px-4`}
        }
      }
    `,
])

const Left = styled.div(() => [
  tw`relative flex items-center justify-center mr-16 cursor-pointer`,
  css`
    width: calc(100% - ${rightWidth}px);
    @media (max-width: 1440px) {
      ${tw`w-1/2`}
    }

    @media (max-width: 1280px) {
      ${tw`mr-0`}
    }

    @media (max-width: 860px) {
      width: 500px;
      max-width: 95%;
    }

    .gatsby-image-wrapper {
      ${tw`transition-all duration-500 ease-in-out`}
    }

    .thumbnail {
      ${tw`z-10 w-full shadow-lg`}
    }

    .play-btn {
      ${tw`absolute! z-20`}
      width: 20%;
    }

    &:hover {
      .thumbnail {
        transform: scale(0.98);
        filter: grayscale(100%);
      }

      .play-btn {
        transform: scale(1.05);
      }
    }
  `,
])

export default ({ video, title, content, button, showVideo }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "play-btn.png" }) {
            childImageSharp {
              fluid(maxWidth: 170, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <Wrapper>
          {showVideo && (
            <ModalContext.Consumer>
              {modal => (
                <Left onClick={() => modal.openModal(video.videoEmbed)}>
                  <Img
                    className="play-btn"
                    fluid={data?.file.childImageSharp?.fluid}
                    alt="Play button"
                  />
                  <Img
                    className="thumbnail"
                    fluid={
                      video?.videoThumbnail?.localFile?.childImageSharp?.fluid
                    }
                    alt={video?.videoThumbnail?.altText}
                  />
                </Left>
              )}
            </ModalContext.Consumer>
          )}
          <Right showVideo={showVideo}>
            <Heading>{title}</Heading>
            <Content>{content}</Content>
            <PopupButton>SCHEDULE A FREE CONSULTATION</PopupButton>
          </Right>
        </Wrapper>
      )}
    />
  )
}
