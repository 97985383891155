import React from "react"
import Content from "./content"
import ReviewList from "./review-list"
import Heading from "./heading"
import tw, { styled, css } from "twin.macro"

const SIDEBAR_WIDTH = 655

const Wrapper = styled.div(() => [
  tw`flex items-start justify-center px-16 py-32`,
  css`
    .content {
      ${tw`pr-16`}
      width: calc(100% - ${SIDEBAR_WIDTH}px);

      @media (max-width: 1440px) {
        width: calc(100% - ${SIDEBAR_WIDTH * 0.7}px);
      }

      @media (max-width: 1280px) {
        ${tw`w-full px-8`}
      }

      @media (max-width: 640px) {
        ${tw`w-full px-4`}
      }
    }

    @media (max-width: 1280px) {
      ${tw`flex-col items-center px-0 pb-0 pt-16`}
    }
  `,
])

const ReviewsContainer = styled.aside(() => [
  tw`px-16 py-32 shadow-lg -mt-48 z-30 bg-white flex flex-col`,
  css`
    h2 {
      ${tw`mb-16`}
    }

    ul {
      ${tw`ml-0`}
    }

    width: ${SIDEBAR_WIDTH}px;

    @media (max-width: 1440px) {
      ${tw`px-4 py-12 text-sm`}
      width: ${SIDEBAR_WIDTH * 0.7}px;
    }

    @media (max-width: 1280px) {
      .heading {
        ${tw`text-2xl mb-6`}
      }
      ${tw`w-full px-16 py-24 mt-8`}
      max-width: ${SIDEBAR_WIDTH}px;
    }

    @media (max-width: ${SIDEBAR_WIDTH}px) {
      ${tw`px-8 py-16`}
      br {
        ${tw`hidden`}
      }
    }
  `,
])

const BottomInnerContent = ({ children, reviews, title }) => (
  <Wrapper>
    <Content>{children}</Content>
    <ReviewsContainer>
      <Heading size="1.5rem" centered>
        {title}
      </Heading>
      <ReviewList reviews={reviews} />
    </ReviewsContainer>
  </Wrapper>
)

export default BottomInnerContent
