import React from "react"
import { graphql } from "gatsby"
import InnerHero from "../components/partials/inner-hero"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import InnerContent from "../components/partials/inner-content"
import { splitContent } from "../../util/functions"
import { ModalProvider } from "../components/modules/modal-context"
import { FormProvider } from "../components/modules/popup-form-context"
import PopupForm from "../components/modules/popup-form"
import { getImage } from "../../util/functions"

export default ({ data }) => {
  //highlight-line
  const page = data.wpPage
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  const content = splitContent(
    page.content,
    data.wp.nextlevel.themeInfo.themeName
  )

  const meta = [robots]
  const seo = page.seo

  const addToMeta = (name, content) => {
    if (content) meta.push({ name: name, content: content })
  }
  addToMeta("og:site_name", seo.opengraphSiteName)
  addToMeta(
    "og:image",
    seo.opengraphImage?.localFile?.publicURL
      ? getImage(seo.opengraphImage.localFile.publicURL, true, false)
      : null
  )
  addToMeta("article:publisher", seo.opengraphPublisher)
  addToMeta("article:modified_time", seo.opengraphModifiedTime)
  addToMeta("article:published_time", seo.opengraphPublishedTime)
  addToMeta("og:type", seo.opengraphType)
  const isSpanish = page.postAttributes?.spanish === true
  return (
    <FormProvider>
      <ModalProvider>
        <Layout spanish={isSpanish}>
          <SEO
            title={page.seo.title}
            description={page.seo.metaDesc}
            meta={meta}
            schema={page.seo.schema?.raw}
          />
          <PopupForm />
          <InnerHero videoContent={page.videoContent} />
          <InnerContent
            content={content}
            options={data.wp}
            spanish={isSpanish}
          />
        </Layout>
      </ModalProvider>
    </FormProvider>
  )
}

export const query = graphql`
  query ($id: String!) {
    wp {
      awards {
        aw {
          awTitle
          awReviews {
            author
            body
            excerpt
          }
        }
      }
      nextlevel {
        themeInfo {
          themeName
        }
      }
      innerPageSettings {
        featuredTestimonial {
          featuredReviewAll {
            url
            title
          }
          featuredReviewAuthor
          featuredReviewTexture {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          featuredReviewBody
          featuredReviewEmbed
          featuredReviewVideoButton
          featuredReviewPortrait {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mediaSidebar {
          mediaSidebarMenuTitle
          mediaSidebarTitle
          mediaSidebarMenu {
            link {
              title
              url
            }
          }
          mediaSidebarButton {
            title
            url
          }
          mediaSidebarBg {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 740) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      content
      seo {
        ...SEO
        ...PostSEO
      }
      id
      uri
      template {
        templateName
      }
      postAttributes {
        spanish
      }
      title
      videoContent {
        ...VideoContent
      }
      ...GrandparentVideoContent
    }
  }
  fragment GrandparentVideoContent on WpPage {
    ...ParentVideoContent
    wpParent {
      node {
        ...ParentVideoContent
      }
    }
  }

  fragment ParentVideoContent on WpPage {
    wpParent {
      node {
        ... on WpPage {
          videoContent {
            ...VideoContent
          }
        }
      }
    }
  }

  fragment VideoContent on WpPage_Videocontent {
    vcShow
    vcContent
    vcTitle
    vcButton {
      title
      url
    }
    vcVideo {
      videoEmbed
      videoThumbnail {
        altText
        localFile {
          childImageSharp {
            fluid(maxWidth: 1190, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
