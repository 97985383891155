import React from "react"
import Content from "./content"
import Form from "./footer-form"
import tw, { styled, css } from "twin.macro"

const Wrapper = styled.div(({ spacing }) => [
  tw`flex items-start justify-center px-16`,
  spacing && tw`mb-48`,
  css`
    .content {
      ${tw`pr-24 w-full`}
      ${!spacing && tw`mt-16`}
    }
    @media (max-width: 1680px) {
      ${tw`px-8`}

      form {
        ${tw`-mx-8`}
      }
    }

    @media (max-width: 1440px) {
      ${tw`flex-col items-center`}

      .content {
        ${tw`pr-0 mb-12`}
      }
    }

    @media (max-width: 640px) {
      ${tw`px-4`}

      form {
        ${tw`-mx-4`}
      }
    }
  `,
])

const Sidebar = tw.aside`-mb-32`

const TopInnerContent = ({ children, spacing, spanish, sitemap }) => (
  <Wrapper spacing={spacing}>
    <Content sitemap>{children}</Content>
    <Sidebar>
      <Form
        title="Schedule a Free Consultation"
        endpoint="https://usebasin.com/f/28f28c984d2c"
        spanish={spanish}
      />
    </Sidebar>
  </Wrapper>
)

export default TopInnerContent
