import React from "react"
import "../../styles/inner-content.css"
import TopInnerContent from "../modules/top-inner-content"
import CenterInnerContent from "../modules/center-inner-content"
import BottomInnerContent from "../modules/bottom-inner-content"
import FeaturedTestimonal from "../modules/featured-testimonial"
import CaseResults from "./case-results"

const InnerContent = ({ content, options, spanish }) => {
  const featuredTestimonial = options.innerPageSettings.featuredTestimonial
  if (content.length > 2) {
    return (
      <>
        <TopInnerContent>{content[0]}</TopInnerContent>
        <FeaturedTestimonal
          title="Featured Testimonial"
          author={featuredTestimonial.featuredReviewAuthor}
          body={featuredTestimonial.featuredReviewBody}
          videoEmbed={featuredTestimonial.featuredReviewEmbed}
          videoButton={featuredTestimonial.featuredReviewVideoButton}
          fluid={
            featuredTestimonial.featuredReviewPortrait.localFile.childImageSharp
              .fluid
          }
          alt={featuredTestimonial.featuredReviewPortrait.altText}
          viewAllButton={featuredTestimonial.featuredReviewAll}
          texture={featuredTestimonial.featuredReviewTexture}
        />
        <CenterInnerContent
          mediaSidebar={options.innerPageSettings.mediaSidebar}
        >
          {content[1]}
        </CenterInnerContent>
        <CaseResults centered />
        <BottomInnerContent
          title={options.awards.aw.awTitle}
          reviews={options.awards.aw.awReviews}
        >
          {content[2]}
        </BottomInnerContent>
      </>
    )
  } else if (content.length === 2) {
    return (
      <>
        <TopInnerContent>{content[0]}</TopInnerContent>
        <CenterInnerContent
          mediaSidebar={options.innerPageSettings.mediaSidebar}
        >
          {content[1]}
        </CenterInnerContent>
      </>
    )
  } else {
    return (
      <TopInnerContent spacing spanish={spanish}>
        {content[0]}
      </TopInnerContent>
    )
  }
}

export default InnerContent
